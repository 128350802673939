import React, { useCallback, useEffect } from 'react'

export default function useOutsideClick(ref, callback, resubscribe) {
  const handleClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    },
    [resubscribe]
  )

  useEffect(() => {
    if (resubscribe) {
      document.addEventListener('click', handleClick, { capture: true })

      return () => {
        document.removeEventListener('click', handleClick, { capture: true })
      }
    }
  }, [resubscribe])
}
