import { ReactComponent as downArrow } from './svg/down-arrow.svg'
import { ReactComponent as outsourcing } from './svg/outsourcing.svg'
import { ReactComponent as outstaffing } from './svg/outstaffing.svg'
import { ReactComponent as product } from './svg/product.svg'

export const SVG_MAP = {
  downArrow,
  outsourcing,
  outstaffing,
  product,
}
