import React from 'react'
import TeamMember from './TeamMember/TeamMember'
// import s from './Team.module.scss'

const members = [
  { name: 'Larysa Kotliakova', photo: '/img/lar.jpg', position: 'Director', bio: '' },
  { name: 'Dmytro (Dima) Shapovalov', photo: '/img/dim.jpg', position: 'CTO', bio: '' },
  { name: 'Lana Tymoshenko', photo: '/img/lana.jpg', position: 'HR Director', bio: '' },
  { name: 'Eugene Okatyi', photo: '/img/eo2.jpg', position: 'Head of Product Management', bio: '' },
  { name: 'Vadym Bochkovskyi', photo: '/img/vb.jpeg', position: 'Head of Test Engineering', bio: '' },
]

function Team() {
  return (
    <>
      {members.map((member) => (
        <TeamMember member={member} key={member.name} />
      ))}
    </>
  )
}

export default Team
