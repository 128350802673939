import React from 'react'
import s from './TeamMember.module.scss'

function TeamMember({ member }) {
  const { name, photo, position } = member

  return (
    <div className={s.root}>
      <img className={s.photo} src={photo} />
      <div className={s.name}>{name}</div>
      <div className={s.position}>{position}</div>
    </div>
  )
}

export default TeamMember
