import React from 'react'
import { Mail } from '../../components'
import s from './Contacts.module.scss'

function Contacts() {
  return (
    <div className={s.root}>
      <div className={s.mail}>
        <Mail />
      </div>
      <div className={s.addressContainer}>
        <h3>Kyiv HQ</h3>
        <div className={s.address}>
          <div>57 Zaluzna Street, Kyiv 02055</div>
          <div>+380 50 388 52 62</div>
          <a href="mailto:info@saldvin.com">info@saldvin.com</a>
        </div>
      </div>
    </div>
  )
}

export default Contacts
