import React from 'react'
import s from './Jobs.module.scss'

function Job() {
  return (
    <div className={s.root}>
      <p>
        We are seeking a Senior Javascript Developer for our team who thrives on pushing the limits of technology to
        produce state of the art solutions. Our Engineering team is constantly challenged to deliver creative,
        imaginative and scalable execution solutions for highly complex trading customers.
      </p>
      <h3>Key Responsibilities</h3>
      <p>
        <ul>
          <li>
            Design, develop and implement web applications. This will include implementing new product features and
            GUI’s, as well as enhancing and maintaining existing functionality, monitoring solutions and performance
            characteristics
          </li>
          <li>Participation in design discussions, review sessions and prototyping </li>
          <li>Successfully integrate data between various systems </li>
          <li>Ensure the scalability and quality of all code </li>
          <li>Assist with product documentation, unit testing and ensuring overall product quality </li>
        </ul>
      </p>
      <h3>Skills Knowledge and Expertise</h3>
      <p>
        <ul>
          <li>Extensive experience with Javascript and web standards</li>
          <li>Knowledge of modern JS tools and frameworks </li>
          <li>Solid understanding of functional programming, object oriented programming and CS foundations </li>
          <li>Experience building scalable, optimal performance web applications</li>
          <li>
            Good understanding of both front end (Javascript, HTML, CSS) and server side (Node.js or Python) components
          </li>
          <li>Proven and strong communication skills </li>
          <li>Ability to understand business requirements and translate to technical requirements </li>
          <li>AWS and Python experience a plus</li>
          <li>Financial trading systems experience is a plus</li>
        </ul>
      </p>
    </div>
  )
}

export default Job
