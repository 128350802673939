import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Footer, Layout, LightLines, Menu, Main } from './components'
import { Careers, Contacts, Services, Team } from './pages'
import s from './App.module.scss'

function App() {
  return (
    <div className={s.App}>
      <header className={s.AppHeader}>
        <div className={s.headTitle}></div>
      </header>
      <div className={s.container}>
        <Menu />
        <Routes>
          <Route
            path="/"
            element={
              <Layout title="About Us">
                <Main />
              </Layout>
            }
          />
          <Route
            path="/team"
            element={
              <Layout title="Our Team">
                <Team />
              </Layout>
            }
          />
          <Route
            path="/services"
            element={
              <Layout title="Engineering Services">
                <Services />
              </Layout>
            }
          />
          <Route
            path="/contacts"
            element={
              <Layout title="Contact Us">
                <Contacts />
              </Layout>
            }
          />
          <Route
            path="/careers"
            element={
              <Layout title="Careers">
                <Careers />
              </Layout>
            }
          />
        </Routes>
      </div>
      <Footer />
      <LightLines />
    </div>
  )
}

export default App
