import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { useOutsideClick } from '../../utils'
import s from './Menu.module.scss'

const menuItems = [
  {
    name: 'About Us',
    path: '/',
  },
  {
    name: 'Team',
    path: '/team',
  },
  {
    name: 'Services',
    path: '/services',
  },
  // {
  //   name: 'Projects',
  //   path: '/projects',
  // },
  {
    name: 'Careers',
    path: '/careers',
  },
  {
    name: 'Contacts',
    path: '/contacts',
  },
]

function Menu() {
  const [activePage, setActivePage] = useState('/')
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const ref = useRef(null)

  useEffect(() => {
    if (location.pathname !== activePage) {
      setActivePage(location.pathname)
      window.dispatchEvent(new Event('locationChanged'))
      if (showMobileMenu) {
        setShowMobileMenu(false)
      }
    }
  }, [location.pathname, activePage, showMobileMenu])

  const triggerShowMobileMenu = useCallback(() => {
    setShowMobileMenu(!showMobileMenu)
  }, [showMobileMenu])

  useOutsideClick(
    ref,
    () => {
      if (showMobileMenu) {
        setShowMobileMenu(false)
      }
    },
    showMobileMenu
  )

  return (
    <div className={s.root}>
      <div className={s.mobileMenuButton} onClick={triggerShowMobileMenu} />
      <ul className={cx(s.menu, { [s.mobileMenuActive]: showMobileMenu })} ref={ref}>
        {menuItems.map(({ name, path }) => (
          <li onClick={() => navigate(path)} key={path} className={cx({ [s.active]: activePage === path })}>
            {name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Menu
