import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import emailjs from '@emailjs/browser'

import s from './Mail.module.scss'

export default function Mail() {
  const form = useRef()
  const [captchaResponse, setCaptchaResponse] = useState(null)
  const [mailSent, setMailSent] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()

    const data = {
      [form.current[0].name]: form.current[0].value,
      [form.current[1].name]: form.current[1].value,
      [form.current[2].name]: form.current[2].value,
      'g-recaptcha-response': captchaResponse,
    }

    emailjs.send('service_ljpjhgk', 'template_saldvin_contact', data, 'b0iVLl4sN3cvrxjHH').then(
      (result) => {
        console.log(result.text)
        setMailSent(true)
      },
      (error) => {
        console.log(error.text)
      }
    )
  }

  return (
    <form ref={form} onSubmit={sendEmail} className={s.root}>
      <div className={s.nameContainer}>
        <div className={s.name}>
          <label className={s.labelName}>Name</label>
          <input type="text" name="user_name" required />
        </div>
        <div>
          <label className={s.labelName}>Email</label>
          <input type="email" name="user_email" required />
        </div>
      </div>
      <label>Message</label>
      <textarea name="message" className={s.message} required />
      {mailSent ? (
        <div className={s.captcha}>Great! Your message was sent. We will answer you not later than in 24 hours.</div>
      ) : (
        <div className={s.captcha}>
          <ReCAPTCHA sitekey="6LdLhX4jAAAAADcvHHRVE4TUIclz3tNN_mtPleHy" onChange={setCaptchaResponse} />
          <input type="submit" value="Send" className={s.submit} />
        </div>
      )}
    </form>
  )
}
