import React, { useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { Icon } from 'components'
import Jobs from './Jobs'
import s from './Careers.module.scss'

const list = [
  { title: 'Senior Backend Developer', id: 'ts103', Component: Jobs['SeniorJavaTS'] },
  { title: 'Senior DevOps', id: 'ts104', Component: Jobs['SeniorDevOpsTS'] },
  { title: 'Senior Javascript Developer', id: 'tt101', Component: Jobs['FullStackJSTT'] },
  { title: 'Swift Engineer', id: 'ts108', Component: Jobs['SwiftTS'] },
]

function Careers() {
  const [opened, setOpened] = useState({})
  const location = useLocation()
  const navigate = useNavigate()

  const triggerOpened = useCallback(
    (id) => {
      if (!opened[id]) {
        navigate(`#${id}`)
      }
      setOpened({ ...opened, [id]: !opened[id] })

      setTimeout(() => window.dispatchEvent(new Event('locationChanged')), 310)
    },
    [opened]
  )

  useEffect(() => {
    if (location.hash && !opened[location.hash]) {
      setOpened({ ...opened, [location.hash.slice(1)]: true })
    }
  }, [])

  return (
    <div className={s.root}>
      <div className={s.tabs}>
        {list.map(({ title, id, Component }) => {
          return (
            <>
              <div className={s.title} onClick={() => triggerOpened(id)}>
                <Icon
                  type="downArrow"
                  className={cx(s.openIcon, 'animate__backInUp', {
                    [s.opened]: opened[id],
                    animate__backInUp: opened[id],
                  })}
                />
                {title}
              </div>
              <div className={cx(s.body, { [s.active]: opened[id] })}>
                <Component />
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Careers
