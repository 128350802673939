;(function (window) {
  var ctx,
    hue,
    target = {},
    tendrils = [],
    settings = {};
    // imagedata;

  settings.friction = 0.5 // 0.5
  settings.trails = 10 // 20 (lines)
  // settings.trails = 20; // 20 (lines)
  settings.size = 80 // 50 (play with it) (trail disappear duration)
  settings.dampening = 0.15 // 0.25
  settings.tension = 0.99 // 0.98

  Math.TWO_PI = Math.PI * 2

  // ========================================================================================
  // Oscillator
  // ----------------------------------------------------------------------------------------

  function Oscillator(options) {
    this.init(options || {})
  }

  Oscillator.prototype = (function () {
    let value = 0

    return {
      init: function (options) {
        this.phase = options.phase || 0
        this.offset = options.offset || 0
        this.frequency = options.frequency || 0.001
        this.amplitude = options.amplitude || 1
      },

      update: function () {
        this.phase += this.frequency
        value = this.offset + Math.cos(this.phase) * this.amplitude
        return value
      },

      value: function () {
        return value
      },
    }
  })()

  // ========================================================================================
  // Tendril
  // ----------------------------------------------------------------------------------------

  function Tendril(options) {
    this.init(options || {})
  }

  Tendril.prototype = (function () {
    function Node() {
      this.x = 0
      this.y = 0
      this.vy = 0
      this.vx = 0
    }

    return {
      init: function (options) {
        this.spring = options.spring + Math.random() * 0.1 - 0.05
        this.friction = settings.friction + Math.random() * 0.01 - 0.005
        this.nodes = []

        for (var i = 0, node; i < settings.size; i++) {
          node = new Node()
          node.x = target.x
          node.y = target.y

          this.nodes.push(node)
        }
      },

      update: function () {
        var spring = this.spring,
          node = this.nodes[0]

        node.vx += (target.x - node.x) * spring
        node.vy += (target.y - node.y) * spring

        for (var prev, i = 0, n = this.nodes.length; i < n; i++) {
          node = this.nodes[i]

          if (i > 0) {
            prev = this.nodes[i - 1]

            node.vx += (prev.x - node.x) * spring
            node.vy += (prev.y - node.y) * spring
            node.vx += prev.vx * settings.dampening
            node.vy += prev.vy * settings.dampening
          }

          node.vx *= this.friction
          node.vy *= this.friction
          node.x += node.vx
          node.y += node.vy

          spring *= settings.tension
        }
      },

      draw: function () {
        var x = this.nodes[0].x,
          y = this.nodes[0].y,
          a,
          b

        ctx.beginPath()
        ctx.moveTo(x, y)

        for (var i = 1, n = this.nodes.length - 2; i < n; i++) {
          a = this.nodes[i]
          b = this.nodes[i + 1]
          x = (a.x + b.x) * 0.5
          y = (a.y + b.y) * 0.5

          ctx.quadraticCurveTo(a.x, a.y, x, y) // quadraticCurveTo
        }

        a = this.nodes[i]
        b = this.nodes[i + 1]

        ctx.quadraticCurveTo(a.x, a.y, b.x, b.y) // quadraticCurveTo
        ctx.stroke()
        ctx.closePath()
      },
    }
  })()

  // ----------------------------------------------------------------------------------------

  function reset() {
    tendrils = []

    for (var i = 0; i < settings.trails; i++) {
      tendrils.push(
        new Tendril({
          spring: 0.45 + 0.025 * (i / settings.trails),
        })
      )
    }
  }

  function loop() {
    ctx.globalCompositeOperation = 'source-over'
    ctx.fillStyle = 'rgba(8,5,16,0.4)'
    // ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    // ctx.putImageData(imagedata, 0, 0, 0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.globalCompositeOperation = 'lighter' // lighter (darker) - soft-light - soft version
    ctx.strokeStyle = 'hsla(' + Math.round(hue.update()) + ',90%,40%,0.10)' // ,90%,50%,0.25 (прозрачней/насыщенней)
    ctx.lineWidth = 2 // default - 1
    // ctx.lineWidth = 1;

    for (var i = 0, tendril; i < settings.trails; i++) {
      tendril = tendrils[i]
      tendril.update()
      tendril.draw()
    }

    requestAnimFrame(loop)
  }

  function resetSize(e) {
    ctx.canvas.width = document.documentElement.innerWidth
    ctx.canvas.height = document.documentElement.innerHeight
    resize()
  }

  function resize() {
    ctx.canvas.width = document.documentElement.scrollWidth;
    ctx.canvas.height = document.documentElement.scrollHeight;
    // imagedata = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  function mousemove(event) {
    if (event.touches) {
      target.x = event.touches[0].pageX
      target.y = event.touches[0].pageY
    } else {
      target.x = event.pageX
      target.y = event.pageY
    }
  }

  function touchstart(event) {
    if (event.touches.length === 1) {
      target.x = event.touches[0].pageX
      target.y = event.touches[0].pageY
    }
  }

  function onClick(event) {
    // ctx.strokeStyle = 'hsla(' + Math.round(hue.update()) + ',90%,40%,1)';
    // ctx.strokeStyle = 'hsla(0, 0%, 100%, 1);';
  }

  const requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (fn) {
        window.setTimeout(fn, 1000 / 60)
      }
    )
  })()

  window.onload = function () {
    ctx = document.getElementById('canvas').getContext('2d')

    hue = new Oscillator({
      phase: Math.random() * Math.TWO_PI,
      amplitude: 700, // colors changing speed (was 85)
      frequency: 0.0015,
      offset: 285, // ??? (was 285)
    })

    document.body.addEventListener('orientationchange', resize)
    window.addEventListener('resize', resize)
    window.addEventListener('locationChanged', resetSize)

    document.addEventListener('mousemove', mousemove)
    document.addEventListener('touchmove', mousemove)
    document.addEventListener('touchstart', touchstart)
    document.addEventListener('click', onClick)

    target.x = Math.random() * ctx.canvas.width
    target.y = Math.random() * ctx.canvas.height

    resize()
    reset()
    loop()

    // kind of a hack ... but trigger a few mousemoves to kick things off

    // mousemove({
    //     clientX: Math.random() * ctx.canvas.width,
    //     clientY: Math.random() * ctx.canvas.height
    // });

    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 100);

    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 500);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 1000);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 2000);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 2500);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 3000);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 3500);
    //
    // setTimeout(function() {
    //     mousemove({
    //         clientX: Math.random() * ctx.canvas.width,
    //         clientY: Math.random() * ctx.canvas.height
    //     });
    // }, 4000);
  }
})(window)

function LightLines() {
  return <canvas id="canvas"></canvas>
}

export default LightLines
