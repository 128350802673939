import React from 'react'
import s from './Layout.module.scss'

function Layout({ title, children }) {
  return (
    <div className={s.root}>
      <div className={s.title}>{title}</div>
      <div className={s.content}>{children}</div>
    </div>
  )
}

export default Layout
