import React from 'react';
import s from './Footer.module.scss';

function Footer() {

  return (
      <div className={s.root}>
        © Copyright 2024 Saldvin
      </div>
  );
}

export default Footer;
