import React from 'react'
import s from './Jobs.module.scss'

function Job() {
  return (
    <div className={s.root}>
      <p>
        Our client's goal is to build products that literally do not exist yet. Creating widespread, complex,
        all-encompassing ecosystems for creative professionals—and once they see the finished product, they get it. It
        clicks. We’re building tools that iron out clunky workflows, that help creatives get paid faster and organize
        assets intelligently, so they can focus less on administration and more on the work they love.
      </p>
      <p>
        Current flagship is a SaaS platform that combines cloud-based collaborative tools with long-term storage and
        high-speed file transfer.
      </p>
      <p>
        You’ll be joining an enthusiastic and motivated project team. The ideal candidate works well in a fast-paced,
        agile, deadline-driven environment and can collaborate professionally and quickly with colleagues to meet goals.
        Innovation and creativity are paramount—we’re problem-solvers, collaborative thinkers and always open to new
        ideas.
      </p>
      <p>
        We’re looking for a senior back-end developer to join our team. If you have experience building digital video
        tools or streaming video for an agency, media or a production company, you’ll fit right in. We encourage you to
        apply.
      </p>
      <h3>Expert Knowledge</h3>
      <p>You understand how to build highly scalable distributed microservices.</p>
      <h3>Mandatory Technologies</h3>
      <p>Java, OOP concepts, Python as a plus</p>
      <h3>Basic Qualifications</h3>
      <p>
        <ul>
          <li>Hands-on coding experience ideally in Java and Python as a plus</li>
          <li>
            Minimum 5-8 years experience deploying and running cloud-based solutions with microservices architecture
          </li>
          <li>Knowledge and experience with distributed systems, their development, operations and observability</li>
          <li>Experience with streaming data pipelines using tools such as Kafka, Spark, Storm or Flink</li>
          <li>
            Expertise with core computer science fundamentals, including object-oriented programming, algorithms, data
            structures and design patterns
          </li>
          <li>Experience with the Vertx framework</li>
          <li>
            Hands-on experience in one or more industry-leading database platforms, both relational and non-relational
            systems, preferably MySQL, Postgres, Redis, etc. (key/value stores, distributed database solutions)
          </li>
          <li>
            Experience with version control (preferably GIT), continuous integration, deployment and configuration
          </li>
          <li>
            Experience in building digital video tools or streaming video for an agency, media or a production company
            preferred
          </li>
          <li>
            Can participate in the full lifecycle of software development (requirements gathering, designing, building,
            testing, maintenance and support)
          </li>
          <li>Able to trace data through varying systems and debug where issues occur</li>
          <li>
            Can influence and drive software engineering and architecture best practices and standards within the team
          </li>
          <li>
            Excellent communication and collaboration skills and a team-focused approach towards software development
          </li>
          <li>
            You care deeply about craftsmanship in your software, and can work backwards from the customer experience
          </li>
          <li>
            Ability to dive deep into any technical component as well as understand and drive the overall systems
            architecture
          </li>
          <li>You’re a self-learner, independent and have excellent problem-solving skills</li>
          <li>Knowledge of modern data solutions and architectures</li>
          <li>Self-motivating and ability to work with minimal direct supervision</li>
        </ul>
      </p>
    </div>
  )
}

export default Job
