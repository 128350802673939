import React from 'react'
import { noop } from 'lodash'
import cx from 'classnames'
import { SVG_MAP } from './IconsMap'
import styles from './Icon.module.scss'

function Icon({ id, type, className, onClick = noop, color = '#fff' }) {
  const IconComponent = SVG_MAP[type]

  if (!IconComponent) {
    return null
  }

  const iconProps = {
    className: cx(styles.root, className),
    onClick,
  }

  if (id) {
    iconProps.id = id
  }

  return (
    <span key={type} {...iconProps}>
      <IconComponent key={type} fill={color} />
    </span>
  )
}

export default Icon
