import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { startsWith } from 'lodash'
import s from './Main.module.scss'

function Main() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.hash && startsWith(location.hash, '#careers')) {
      navigate(location.hash.slice(1))
    }
  }, [])

  return (
    <div className={s.root}>
      We are digital advisors and developers.
      <br />
      Building solutions that brings users digital experience to the next level.
      <br />
      Getting things done. With quality, speed and innovations.
      <div className={s.logos}>
        <ReactSVG src="/svg/databricks.svg" className={s.icon} />
        <ReactSVG src="/svg/pega.svg" className={s.icon} />
        <ReactSVG src="/svg/ibm.svg" className={s.icon} />
        <ReactSVG src="/svg/snowflake.svg" className={s.icon} />
        <ReactSVG src="/svg/trifacta.svg" className={s.icon} />
      </div>
    </div>
  )
}

export default Main
