import React from 'react'
import s from './Jobs.module.scss'

function Job() {
  return (
    <div className={s.root}>
      <p>We’re looking for a Swift engineer to join our team.</p>
      <h3>Mandatory Technologies</h3>
      <p>Swift technologies, UIKit, CoreData, Core Animation, Auto Layout</p>
      <h3>Basic Qualifications</h3>
      <p>
        <ul>
          <li>5+ years of experience engineering products using Swift</li>
          <li>Able to confidently interact with GraphQL / RESTful services</li>
          <li>Capable of using XCTest to write unit tests</li>
          <li>
            Understands application architecture, good design patterns, dependency injection and async flow management
            techniques
          </li>
          <li>Can offer samples of work on the App Store, GitHub or other platforms</li>
          <li>Knowledge of Swift Package Manager, Combine, RxSwift </li>
          <li>Background or interest in film/TV production, the media industry or other creative space </li>
          <li>Leadership skills to help developer tooling and automation </li>
          <li>Understanding of common sense best practices to steer architectural decisions</li>
          <li>Excellent written and verbal communication skills</li>
          <li>Comfortable with technical ownership and working as part of a global team</li>
          <li>Self-starter with a sense of urgency and a commitment to quality and professionalism</li>
        </ul>
      </p>
    </div>
  )
}

export default Job
