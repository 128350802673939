import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { Icon } from 'components'
import s from './Services.module.scss'

function Services() {
  const navigate = useNavigate()
  const goToContacts = useCallback(() => {
    navigate('/contacts')
  }, [])

  return (
    <div className={s.root}>
      Saldvin’s expertise is rooted in technology—from digital product innovation, to total business transformation. Our
      engineering services provide the fundamental building blocks to make technology work across the enterprise.
      Streamline and optimize your
      technology infrastructure through an agile approach to deliver solutions quickly and efficiently.
      <div className={s.services}>
        <div className={s.serviceBlock}>
          <Icon type="outstaffing" className={s.serviceIcon} />
          <h3>Outstaffing</h3>
          <div className={s.desc}>
            Brilliant engineers to your team.
            We staff outstanding engineers to extend your team.
          </div>
        </div>
        <div className={s.serviceBlock}>
          <Icon type="outsourcing" className={s.serviceIcon} />
          <h3>Outsourcing</h3>
          <div className={s.desc}>Our full-fledged teams work on create/enhance/support your apps and services.</div>
        </div>
        <div className={s.serviceBlock}>
          <Icon type="product" className={s.serviceIcon} />
          <h3>Product solutions</h3>
          <div className={s.desc}>Complete products creation. Our team handles all aspects of creating incredible products for your business.</div>
        </div>
      </div>

      <div className={s.footer}>Let's talk about your business needs!</div>
      <button onClick={goToContacts}>Contact Us</button>
    </div>
  )
}

export default Services
