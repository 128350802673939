import React from 'react'
import s from './Jobs.module.scss'

function Job() {
  return (
    <div className={s.root}>
      <p>
        Our client's goal is to build products that literally do not exist yet. Creating widespread, complex,
        all-encompassing ecosystems for creative professionals—and once they see the finished product, they get it. It
        clicks. We’re building tools that iron out clunky workflows, that help creatives get paid faster and organize
        assets intelligently, so they can focus less on administration and more on the work they love.
      </p>
      <p>
        Current flagship is a SaaS platform that combines cloud-based collaborative tools with long-term storage and
        high-speed file transfer.
      </p>
      <p>
        You’ll be joining an enthusiastic and motivated project team. The ideal candidate works well in a fast-paced,
        agile, deadline-driven environment and can collaborate professionally and quickly with colleagues to meet goals.
        Innovation and creativity are paramount—we’re problem-solvers, collaborative thinkers and always open to new
        ideas.
      </p>
      <p>We’re looking for a senior DevOps to join our team.</p>
      <h3>Expert Knowledge</h3>
      <p>Highly scalable distributed microservices.</p>
      <h3>Mandatory Technologies</h3>
      <p>IBM Cloud</p>
      <h3>Basic Qualifications</h3>
      <p>
        <ul>
          <li>Hands-on experience ideally in IBM Cloud</li>
          <li>
            Minimum 5-8 years experience deploying and running cloud-based solutions with microservices architecture
          </li>
          <li>Experience with CI/CD creation</li>
          <li>Docker</li>
          <li>Kubernetes</li>
          <li>
            Excellent communication and collaboration skills and a team-focused approach towards software development
          </li>
          <li>
            Ability to dive deep into any technical component as well as understand and drive the overall systems
            architecture
          </li>
          <li>You’re a self-learner, independent and have excellent problem-solving skills</li>
          <li>Knowledge of modern data solutions and architectures</li>
          <li>Self-motivating and ability to work with minimal direct supervision</li>
        </ul>
      </p>
    </div>
  )
}

export default Job
